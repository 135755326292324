import Preact from "preact";
import { NavStateProvider } from "./Context.tsx";
import MobileNav from "./MobileToggle.tsx";
import SocialNav from "./Social.tsx";
import MainNav from "./MainNav.tsx";

export default function Header(props) {
  const { open = false } = props;
  return (
    <NavStateProvider>
      <header class="menu container">
        <div class="logo-container">
          <a class="logo" href="/">
            <img src="/img/logo.png" alt="Boyd Logo" />
          </a>
          <MobileNav />
        </div>
        <SocialNav />
        <MainNav />
      </header>
    </NavStateProvider>
  );
}
